import React from 'react';
import styles from './ContactUsMainForm.module.css';
import human from "../../images/human.png";
import ContactUsForm from "../ContactUsForm/ContactUsForm";

const ContactUsMainForm = () => {
    return (
        <div className={styles.contactsMain}>
            <h4 className={styles.writeUs}>Напишите нам и мы свяжемся с вами в ближайшее время!</h4>
            <div className={styles.contacts}>
                <div className={styles.contactInfo}>
                    <img src={human} alt=''/>
                    <b>Наш адрес</b>
                    <p>г.&nbsp;Томск ул.&nbsp;Большая Подгорная, 61/1</p>
                    <b>Контакты</b>
                    <div className={styles.contact}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                                fill="#5f5f5f"/>
                        </svg>
                        <a>+7‒952‒176‒28‒28</a>
                    </div>
                    <div className={styles.contact}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                                fill="#5f5f5f"/>
                        </svg>
                        <a>783-064</a>
                    </div>
                    <b>Наши страницы в&nbsp;соцсетях</b>
                    <div className={styles.contact} onClick={() => window.location.href = 'https://vk.com/4zveta'}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23"
                             viewBox="0,0,256,256"
                             fill="#5f5f5f">
                            <g transform="translate(-12.8,-12.8) scale(1.1,1.1)">
                                <g fill="#5f5f5f" fill-rule="nonzero" stroke="none" stroke-width="1"
                                   stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                                   stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                                   font-size="none" text-anchor="none">
                                    <g transform="scale(5.12,5.12)">
                                        <path
                                            d="M16,3c-7.17,0 -13,5.83 -13,13v18c0,7.17 5.83,13 13,13h18c7.17,0 13,-5.83 13,-13v-18c0,-7.17 -5.83,-13 -13,-13zM37,11c1.1,0 2,0.9 2,2c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2zM25,14c6.07,0 11,4.93 11,11c0,6.07 -4.93,11 -11,11c-6.07,0 -11,-4.93 -11,-11c0,-6.07 4.93,-11 11,-11zM25,16c-4.96,0 -9,4.04 -9,9c0,4.96 4.04,9 9,9c4.96,0 9,-4.04 9,-9c0,-4.96 -4.04,-9 -9,-9z"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className={styles.link}>vk.com/4zveta</div>
                    </div>
                    <div className={`${styles.contact} mt-1`}
                         onClick={() => window.location.href = 'https://www.instagram.com/4zveta/'}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="23" height="23"
                             viewBox="0,0,256,256"
                             fill="#5f5f5f">
                            <g transform="translate(-37.33333,-37.33333) scale(1.29167,1.29167)">
                                <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                                   stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray=""
                                   stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none"
                                   text-anchor="none">
                                    <g transform="scale(5.33333,5.33333)">
                                        <path
                                            d="M42,37c0,2.762 -2.238,5 -5,5h-26c-2.761,0 -5,-2.238 -5,-5v-26c0,-2.762 2.239,-5 5,-5h26c2.762,0 5,2.238 5,5z"
                                            fill="#5f5f5f"></path>
                                        <path
                                            d="M35.937,18.041c0.046,-0.151 0.068,-0.291 0.062,-0.416c-0.015,-0.362 -0.264,-0.625 -0.85,-0.625h-2.618c-0.661,0 -0.966,0.4 -1.144,0.801c0,0 -1.632,3.359 -3.513,5.574c-0.61,0.641 -0.92,0.625 -1.25,0.625c-0.177,0 -0.624,-0.214 -0.624,-0.801v-5.185c0,-0.694 -0.173,-1.014 -0.732,-1.014h-4.649c-0.407,0 -0.619,0.32 -0.619,0.641c0,0.667 0.898,0.827 1,2.696v3.623c0,0.88 -0.153,1.04 -0.483,1.04c-0.89,0 -2.642,-3 -3.815,-6.932c-0.254,-0.774 -0.508,-1.068 -1.169,-1.068h-2.643c-0.763,0 -0.89,0.374 -0.89,0.774c0,0.721 0.6,4.619 3.875,9.101c2.375,3.25 5.504,5.125 8.274,5.125c1.678,0 1.85,-0.427 1.85,-1.094v-2.972c0.001,-0.801 0.184,-0.934 0.718,-0.934c0.381,0 1.158,0.25 2.658,2c1.73,2.018 2.044,3 3.036,3h2.618c0.608,0 0.957,-0.255 0.971,-0.75c0.003,-0.126 -0.015,-0.267 -0.056,-0.424c-0.194,-0.576 -1.084,-1.984 -2.194,-3.326c-0.615,-0.743 -1.222,-1.479 -1.501,-1.879c-0.187,-0.261 -0.258,-0.445 -0.249,-0.621c0.009,-0.185 0.105,-0.361 0.249,-0.607c-0.026,0 3.358,-4.751 3.688,-6.352z"
                                            fill="#ffffff"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className={styles.link}>instagram.com/4zveta</div>
                    </div>
                </div>
                <ContactUsForm/>
            </div>
        </div>
    );
};

export default ContactUsMainForm;